import React from "react";
import Tracker from "../fragments/Tracker";
import SectionBanner from "../fragments/SectionHeader";
import Modal from "../fragments/Modal";
import BookWhen from "../Bookwhen";
import './Book.css'

export default function Book () {
  return (   
    <Tracker title="Book">
      <div>
        <div className="w3-container w3-padding-32 card-bg" id="book">
          <SectionBanner text='How to Book' />
          <p>
            We know how important flexibility is to new parents, which is why we offer both
            block booking and pay-as-you-go options for all classes.
          </p>
          <Modal title="BookWhen_Page" btnText="Click here for more info" bodyHtml={BookWhen} btnClass="btn" />
        </div>
      </div>
    </Tracker>
  );
}
