import React, { ComponentType } from "react";
import ReactModal from "react-modal";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import './Modal.css'

interface IModalOptions {
  title: string,
  btnClass: string
  btnText: string
  bodyHtml: JSX.Element
}

const Modal = ({title, btnText, bodyHtml, btnClass}: IModalOptions) => {
  
  let opened: number;
  let closed: number;

  const SafeModal = ReactModal as ComponentType<ReactModal['props']>;
  const [showModal, setShowModal] = React.useState(false);
  
  const appInsights = useAppInsightsContext();
  const track = (eventName: string, duration?: number) => {
    appInsights.trackEvent({ name: eventName }, { item: title, duration });
  }

  const handleOpen = (event: React.MouseEvent) => {
    track("ModelOpened");
    opened = Date.now();
    setShowModal(true);
    event.preventDefault();
  }

  const handleClose = (event: React.MouseEvent) => {
    track("ModelClosed");
    closed = Date.now();
    track("ModelDuration", closed - opened);
    setShowModal(false);
    event.preventDefault();
  }

  return (
    <>
      <SafeModal 
        isOpen={showModal} 
        shouldCloseOnOverlayClick={true} 
        shouldCloseOnEsc={true}
        onRequestClose={handleClose}
        className="modal"
        overlayClassName="overlay">
        <button className="btn" onClick={handleClose}>Close</button>
        {bodyHtml}
      </SafeModal>
      <button onClick={handleOpen} className={btnClass}>{btnText}</button>
    </>
  );
}

export default Modal;
