import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, withAITracking, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import NavBar from './components/NavBar';
import Header from './components/Header';
import PageHolder from './components/PageHolder';
import './App.css';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=6d774a6e-fcbe-4802-8239-82c8fa629140;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();

const App: React.FC = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div className="App">
        <NavBar />
        <Header />
        <PageHolder />
      </div>
    </AppInsightsContext.Provider>
  );
}

export default withAITracking(reactPlugin, App);
