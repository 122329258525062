import React from "react";
import SectionBanner from "../fragments/SectionHeader";
import Photo from "./Photo";

export default function Classes () {
  return (
    <div>
      <div className="w3-container w3-padding-32 card-bg" id="classes">
        <SectionBanner text='Our Classes' />
        <p>
          Peepo classes use song, makaton signing, tactile activities, visual aids 
          and gentle physical activities based on baby and toddler yoga. We also teach 
          gentle massage to aid with digestion, calming, sleep and proprioceptional development.</p>
          
        <Photo src='images/sensory.jpg' width='100%' />
        
        <p>
          Classes are designed to give parents and carers a range of tools and ideas to 
          take away and play with their babies and toddlers at home.</p>
          
        <Photo src='images/sensory2.jpg' width='100%' />

        <p>Activities are built around a different theme each week.</p>
      </div>
    </div>
  )
}
  