import React from "react";

const BookWhen = (
  <iframe 
    id="booking-frame" 
    src="https://bookwhen.com/peeposensory/iframe?sifbst=z908l2irs4w7qdrnz9adjxnd6bzk" 
    title='Booking Site' 
    scrolling='yes' 
    className="frame"></iframe>
);

export default BookWhen;
