import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from '@fortawesome/free-regular-svg-icons'
import './Card.css'

interface ICardProps {
  title: string,
  desc: string,
  icon: IconDefinition
}

const Card = ({title, desc, icon}: ICardProps) => {
  return(
    <div className='box pink'>
      <div className="padded">
        <FontAwesomeIcon icon={icon} />
      </div>
      <h2>{title}</h2>
      <p>{desc}</p>
    </div>
  );
}

export default Card;
