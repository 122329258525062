import React from "react";
import './Welcome.css'
import Photo from "./Photo";
import SectionBanner from "../fragments/SectionHeader";
import Tracker from "../fragments/Tracker";

export default function Welcome () {
  return (
    <Tracker title="Welcome">
      <div className="welcome-container card-bg" id="welcome">
        <div className="welcome-body">   
          <SectionBanner text='Welcome' />
          <p>
            <span className='mauve-bold'>Peepo Sensory</span> offer fun and sociable classes for babies up to 12 months. Each class 
            incorporates a range of activities which will help to support your baby's development. 
          </p>
          <p>
            <span className='pink-bold'>Peepo Tots</span> offer classes for babies on the move and toddlers up to 3 years old. 
            These classes use movement, song, sensory activities and play to explore our weekly themes and aid age appropriate development.
            Our classes are supportive and sociable, where new parents and carers can meet other families and enjoy a range of sensory 
            focused activities with their children. 
          </p>
        </div>
        <div>        
          <Photo src='images/sensory_play.jpg' width='100%' />
        </div>
      </div>
    </Tracker>
  );
}
