import settings from '../settings.json'
import axios from 'axios'
import { ContactDetails } from '../components/sections/Contact';

export default class Api {

  private static baseUrl: string = settings.apiBaseUrl;
  private static url = (name: string): string => `${this.baseUrl}/${name}`;

  static async sendContact (contact: ContactDetails): Promise<boolean> {
    try {
      const {status, statusText} = 
        await axios.post("https://peepomailer.azurewebsites.net/api/Sender", contact);

      if (status !== 200)
        throw new Error(`${status}: ${statusText}`);

    } catch (err) {
      throw err;
    }
    return true;
  }
}
