import { faHandPaper, faEye, faLightbulb } from '@fortawesome/free-regular-svg-icons'
import { faPodcast, faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import React from "react";
import Tracker from '../fragments/Tracker';
import SectionBanner from "../fragments/SectionHeader";
import Card from '../fragments/Card';
import './sections.css';

export default function Development () {
  return (
    <Tracker title="Development">
      <div>
        <div className="w3-container w3-padding-32 card-bg" id="development">
          <SectionBanner text='Development' />
          <div>
            <div className='flex flex-row'>
              <div className='flex flex-col auto-margins'>
                <p>
                  Each class contains a range of activities designed to engage 
                  and support your baby and toddler with their development.</p>
                <div className="row-container">
                  <Card icon={faHandPaper} title="Touch" desc="Feeling textures and sensations" />
                  <Card icon={faEye} title="Vision" desc="Exploring shapes and colours" />
                  <Card icon={faPodcast} title="Hearing" desc="Listening to directional sounds" />
                </div>                
                <div className="row-container">
                  <Card icon={faBalanceScale} title="Vestibular" desc="Developing a sense of balance" />
                  <Card icon={faLightbulb} title="Proprioception" desc="Being self aware and coordinated" />
                </div>  
              </div>    
            </div> 
          </div>
        </div>
      </div>
    </Tracker>
  )
}
