import React from "react";

export default function Header() {
  return (
    <header 
      className="w3-display-container w3-content w3-wide" 
      style={{ maxWidth: '1200px', marginTop: '3em' }} id="home">
      <img className="w3-image" src="/images/new_header.png" alt="Peepo" width="100%" />
      <div>
        <div className="block">
          <div>
            <img src='/images/peepo_logo_new.png' alt="babyfeet" />
          </div>
        </div>
      </div>
    </header>
  )
}
