import React from "react";
import Tracker from "../fragments/Tracker";
import SectionBanner from "../fragments/SectionHeader";
import './About.css'

export default function About () {  
  return (    
    <Tracker title="About">
      <div className="w3-container w3-padding-32 card-bg" id="about">
        <SectionBanner text='About' />
        <div className="about-container">
          <div>    
              <p>
                Peepo Sensory was founded by Jennie Toland to support new parents in her local area. 
                Having moved to a new community during the 2021 lock-down and had her first child, 
                there was a realisation that new families needed more opportunities to connect. As a 
                parent and former teacher, it made sense to link this to activities which would also 
                support development and learning for the babies.</p>

              <p>
                Using her own experiences as a new parent and a background in SEN teaching, Jennie 
                developed and grew Peepo Sensory. Fun, but with a strong focus on development, these 
                baby classes aim to connect parents and carers socially, in a supportive and encouraging environment.</p>

              <p>
                Since starting the classes, they have grown into a wonderful community and we now have 
                Jill on board delivering the baby classes each week. Jill brings her wealth of parenting 
                knowledge to the community alongside her previous experience of volunteering in Early Years settings.</p>
              
              <p>
                We 're so proud of the community which has grown out of the classes and excited to now be 
                offering classes for older babies and toddlers.</p>
          </div>
        </div>
      </div>
    </Tracker>
  );
}
