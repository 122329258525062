import React from "react";
import Tracker from "../fragments/Tracker";

export interface FooterProps {
  copy: string
}

export default function Footer({ copy }: FooterProps) {
  return (
    <Tracker title="Copyright">
      <footer className="w3-center w3-padding-16">
        <a href="Policies.zip"><p>Click here to view our Policies</p></a>
        <p>&copy; {copy}</p>
      </footer>
    </Tracker>
  );
}