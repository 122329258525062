import React from "react";

export interface BannerProps {
  text: string
}

export default function SectionBanner ({text}: BannerProps) {

  const styles = {
    container: {
      display: 'flex'
    },
    label: {
      width: '100%',
      borderBottom: '1px solid #bbb',
      fontFamily: 'Fredoka',
      fontSize: '3em'
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.label}>{text}</h1>
    </div>
  );
}