import React from "react";
import SectionBanner from "../fragments/SectionHeader";
import Photo from "./Photo";
import "./Locations.css";
import Tracker from "../fragments/Tracker";

export default function Locations () {
  return (
    <Tracker title="Locations">
      <div>
        <div className="w3-container w3-padding-32 card-bg" id="locations">
          <SectionBanner text='Locations' />
            <div className="flex flex-map">
              
              <div className="map-card">
                <a className="map-link" target={"_blank"} rel="noreferrer" href="https://www.google.co.uk/maps/place/Gill+Nethercott+Centre/@51.2279748,-1.3413818,17z/data=!3m1!4b1!4m6!3m5!1s0x487404c3b5f9b79d:0xb6f1685e270ff2c0!8m2!3d51.2279715!4d-1.3388069!16s%2Fg%2F1hhhp7vqb">
                  <p>Gill Nethercott Centre<br />Winchester St<br />Whitchurch<br />RG28 7HP</p>
                  <Photo src='images/map.png' width='100%' />
                </a>
              </div>
              
              <div className="map-card">
                <a className="map-link" target={"_blank"} rel="noreferrer" href="https://www.google.com/maps/place/St+Luke's+Hall,+Overton/@51.2415923,-1.2631775,17z/data=!4m6!3m5!1s0x48741b9bb253a279:0xd7156b2dffa6d155!8m2!3d51.2424956!4d-1.2624652!16s%2Fg%2F11f5jmybpf">
                  <p>St Luke's Hall<br />49-55 Winchester St<br />Overton<br />RG25 3HT</p>
                  <Photo src='images/map4.png' width='100%' />
                </a>
              </div>
            
            </div>
            <p className="location-footer">All venues have parking and are easily accessible for those bringing prams.</p>
        </div>
      </div>
    </Tracker>
  )
}
