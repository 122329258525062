import React from "react";
import Welcome from "./sections/Welcome";
import Themes from "./sections/Themes";
import About from "./sections/About";
import Classes from "./sections/Classes";
import Development from "./sections/Development";
import Locations from "./sections/Locations";
import Book from "./sections/Book";
import Contact, { ContactProps } from "./sections/Contact";
import Footer, { FooterProps } from "./sections/Footer";

export default function PageHolder () {
  const copyright = `Peepo Sensory ${new Date().getFullYear()}`;
  const strapline = 'Get in touch, we would love to hear from you...';
  const fprops: FooterProps = { copy: copyright };
  const cprops: ContactProps = { strapline };

  return (
    <div className="container">
      <Welcome />
      <Classes />
      <Themes />
      <Development />
      <Locations />
      <Book />
      <About />
      <Contact {...cprops} />
      <Footer {...fprops}  />
    </div>
  );
}
