import React from "react";
import './Burger.css'
import './Menu.css'

const Burger = () => {
  const checkBoxRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const closeMenu = () => {
    checkBoxRef.current.checked = !checkBoxRef.current.checked;
  }
  return (
    <div className="burger-menu">
      <input type="checkbox" id="burger-box" ref={checkBoxRef} />
      <label id="burger-icon" htmlFor="burger-box"> 
        <span></span>
        <span></span>
        <span></span>
      </label>
      
      <div id="bmenu" className="menu" onTouchMove={closeMenu}>
        <img src='./images/logo64.png' alt="Peepo Sensory Logo" />
        <div className="link-container">
          <a onClick={closeMenu} href='#classes'>Classes</a>
          <a onClick={closeMenu} href='#themes'>Themes</a>
          <a onClick={closeMenu} href='#development'>Development</a>
          <a onClick={closeMenu} href='#locations'>Locations</a>
          <a onClick={closeMenu} href='#book'>Booking</a>
          <a onClick={closeMenu} href='#about'>About</a>
          <a onClick={closeMenu} href='#contact'>Contact</a>
          <a onClick={closeMenu} href='Policies.zip'>Policies</a>
        </div>
      </div>
    </div>
  );
}

export default Burger;
