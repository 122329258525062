import React from "react";

export interface PhotoProps {
  src: string, 
  width: string
}

export default function Photo ({src, width}: PhotoProps) {
  //const w100 = { width: '100%' };
  return (
    <div className="w3-container">
      <img src={src} className="w3-image" style={{ width }} alt="" />
    </div>
  );
}