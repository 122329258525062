import React from "react";
import Api from "../../utilities/Api";
import SectionBanner from "../fragments/SectionHeader";
import Tracker from "../fragments/Tracker";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export interface ContactProps {
  strapline: string
}

export type ContactDetails = {
  name?: string,
  email?: string,
  subject?: string,
  comment?: string
}

export default function Contact ({strapline}: ContactProps) {
  const [contact, setContact] = React.useState<ContactDetails>({});
  const [sent, setSent] = React.useState(false);
  
  const appInsights = useAppInsightsContext();

  const updateContact = (func: (d: ContactDetails) => void) => {
    const c = contact;
    func(c);
    setContact(c);
  }
  const validateEmail = (email: string) => {
    // eslint-disable-next-line
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }
  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    appInsights.trackEvent({ name: "ContactUs_Submitted" });
    const success = await Api.sendContact(contact);
    if (success) {
      setSent(true);
    }
  }
  const styles = {
    container: {
      maxWidth: '700px',
      margin: 'auto'
    },
    button: {
      textWeight: '900'
    },
    icon: {
      marginRight: '0.5em'
    },
    label: {
      color: 'red'
    }
  };

  return (
    <Tracker title="Contact">
      <div className="w3-container w3-padding-32 card-bg" id="contact">
        <SectionBanner text='Contact Us' />
        <p>{strapline}</p>
        {sent &&
          <h3>Thanks for getting in touch, we'll get back to you soon.</h3>
        } 
        {!sent &&
          <form onSubmit={submit} style={styles.container}>
            <input className="w3-input w3-border" type="text" placeholder="Name" required 
              onChange={e => updateContact(c => c.name = e.target.value)} />

            <input className="w3-input w3-section w3-border" type="text" placeholder="Email" required 
              onChange={e => {
                const email = e.target.value;
                if (validateEmail(email)) {
                  updateContact(c => c.email = e.target.value);
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity("This is not a valid email address");            
                }
              }} />

            <input className="w3-input w3-section w3-border" type="text" placeholder="Subject"
              onChange={e => updateContact(c => c.subject = e.target.value)} />
            
            
            <textarea className="w3-input w3-section w3-border" rows={5} placeholder="Message" required
              onChange={e => updateContact(c => {
                // eslint-disable-next-line
                c.comment = e.target.value.replace(new RegExp('\n', 'g'), '<br />')
              })} />

            <button className="btn" type="submit">
              <i className="fa fa-paper-plane" style={styles.icon}></i> SEND MESSAGE
            </button>
          </form>
        }
      </div>
    </Tracker>
  );
}