import React from "react";
import SectionBanner from "../fragments/SectionHeader";
import Tracker from "../fragments/Tracker";
import './Themes.css'

interface IContainerProps {
  title: string;
  img: string;
}

export default function Themes () {  
  const Container = ({title, img}: IContainerProps) => {
    return (
      <div className="theme-box">
        <div className="w3-black w3-padding">{title}</div>
        <img src={img} alt="House" className="baseimg" />
      </div>
    );
  }

  return (
    <Tracker title="Themes">
      <div className="w3-container w3-padding-32 card-bg" id="themes">
        <SectionBanner text='Themes' />
        <p>
          Each week our classes will be based on a different theme.
          Examples of our upcoming themes are:
        </p>
        <div className="theme-container">
          <Container title="At the seaside" img="/images/themes/seaside.png" />
          <Container title="Outer Space" img="/images/themes/space.png" />
          <Container title="On The Farm" img="/images/themes/farm.png" />
          <Container title="The Circus" img="/images/themes/circus.png" />
          <Container title="Super Heroes" img="/images/themes/superhero.png" />
          <Container title="At The Zoo" img="/images/themes/zoo.png" />
        </div>
      </div>
    </Tracker>
  );
}