import React from "react";
import VisibilitySensor from 'react-visibility-sensor';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export interface TrackerProps {
  title: string;
  children: any;
}

export default function Tracker ({title, children}: TrackerProps) {
  const appInsights = useAppInsightsContext();
  let viewStart: number;
  let viewEnd: number;
  let viewDuration: number;

  const trackEvent = (pageView: any) => appInsights.trackEvent({ name: "PageView" }, pageView);
  
  return (    
    <VisibilitySensor
        onChange={(isVisible: boolean) => {
          if (isVisible) {
            viewStart = Date.now();
          } else {
            viewEnd = Date.now();
            viewDuration = viewEnd - viewStart;
            trackEvent({ title: title, start: viewStart, end: viewEnd, duration: viewDuration });
          }
        }}
      >
      {children}
    </VisibilitySensor>
  );
}
