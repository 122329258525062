import React from "react";
import BookWhen from "./Bookwhen";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Burger from "./fragments/Burger";
import Modal from "./fragments/Modal";
import './NavBar.css';

export default function NavBar () {
  const appInsights = useAppInsightsContext();
  const Track = (title: string) => {
    appInsights.trackEvent({name: "MenuClick" }, { item: title });
  }
  return (
    <div className="navcontainer">
      <div className="navbar">
        <a href="#home" className="navbtn logobtn"> 
          <img className='navlogo' src="images/logo64.png" alt="baby" />
          <b>peepo</b> <span>sensory</span>
        </a>
        <Modal title="BookWhen_Nav" btnText="Book Now" bodyHtml={BookWhen} btnClass="bookbtn btn" /> 
        <div className="links">
          <div className="linkdiv">
            <a href="#classes" className="navbtn" onClick={() => Track("Classes")}>Classes</a>
            <a href="#themes" className="navbtn" onClick={() => Track("Themes")}>Themes</a>
            <a href="#development" className="navbtn" onClick={() => Track("Development")}>Development</a>
            <a href="#locations" className="navbtn" onClick={() => Track("Locations")}>Locations</a>
            <a href="#book" className="navbtn" onClick={() => Track("Book")}>Book</a>
            <a href="#about" className="navbtn" onClick={() => Track("About")}>About</a>
            <a href="#contact" className="navbtn" onClick={() => Track("Contact")}>Contact</a>
            <a href='Policies.zip'className="navbtn" onClick={() => Track("Policies")}>Policies</a>
          </div>
        </div>
        <Burger />
      </div>
    </div>
  );
}
